@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        @apply font-sans text-gray-700;
    }

    h1 {
        @apply text-4xl font-bold;
    }

    *:not(#rendered) > h2 {
        @apply text-3xl font-bold mb-6 text-center;
    }

    h3 {
        @apply text-2xl font-bold mb-4;
    }

    p {
        @apply mt-3;
    }

    #rendered ul {
        @apply list-disc;
    }

    #rendered ol {
        @apply list-decimal;
    }

    code {
        @apply bg-gray-100 p-1 rounded-md;
    }

    a {
        @apply text-blue-600;
    }

    a:hover {
        @apply underline;
    }
}

a.anchorjs-link:hover {
    @apply no-underline;
}

#template h2 > a.anchorjs-link,
#faq h2 > a.anchorjs-link {
    @apply text-blue-400;
}

/* For code examples in the rendered template, avoid padding because the div
   that comes with react-syntax-highlighter has padding. */
.markdown-body pre {
    padding: 0px;
}

/* This is a hacky fix for an issue with syntax highlighting. See the comment in
   SyntaxHighlighter.tsx. */
.markdown-body pre > code {
    display: block;
    overflow-x: auto;
    background: rgb(43, 43, 43);
    color: rgb(248, 248, 242);
    padding: 0.5em;
}
